import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutUs = () => {
    return (
        <Layout>
            <Seo title="About Us" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>About Us</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-lg-6 d-flex justify-content-center ">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../images/other/singapore1.jpg"
                                    width={696}
                                    layout="fixed"
                                />
                            </div>
                            <div className="col-lg-6 ">
                                <div className="card-body h-100 gray-bg d-flex flex-column">
                                    <p>
                                        Delta Corp, was setup in 2019 to position itself as a customer centric logistic and supply
                                        chain platform, providing transportation of bulk commodities, management of asset management
                                        along the supply chain and Fuel & Lub oil Supply services.
                                    </p>
                                    <p>
                                        As global trade patterns constantly evolve and transform due to shift in economies and
                                        utilization of technology, no "one solution" fits all. Delta Corp, offers customized &
                                        client-centric solutions for their logistic and asset management requirements.
                                    </p>
                                    <p>
                                        Delta Corp seeks to navigate high-growth and niche markets with an adventurous spirit of
                                        discovery and has positioned itself for success by building the foundations with;
                                    </p>
                                    <div>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item gray-bg ">Strong Anchor Investor base</li>
                                            <li className="list-group-item gray-bg">Proven Management</li>
                                            <li className="list-group-item gray-bg">Well Capitalised</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AboutUs
